<template>
  <div class="wrap-content">
    <small>{{ $t("knowquds description") }}</small>
  </div>
</template>

<script>
export default {
  name: "KnowqudsSecondaryDropdown",
};
</script>

<style scoped lang="scss">
.wrap-content {
  padding: 32px;
  width: 500px;
}
</style>
