<template>
  <footer>
    <img
      data-src="/wp-content/themes/qudsinfo-wp-theme/layout/src/images/bg2.jpg"
      class="overlay-image lazy"
    >
    <BContainer>
      <BRow>
        <BCol
          lg="6"
          sm="12"
          cols="12"
          class="mb-4 mb-lg-0"
        >
          <div
            class="footer-brand justify-content-center justify-content-md-start"
          >
            <img
              src="@/images/logo.png"
              alt="Logo"
            >
            <div class="footer-slogan">
              <h3>Qudsinfo.com</h3>
              <span>{{ $t("slogan") }}</span>
            </div>
          </div>
          <FollowButtons
            class="mt-4 mb-3 justify-content-center justify-content-md-start"
          />
          <div class="copyrights mt-5 d-none d-lg-block">
            <b>{{ $t("copyrights") }} {{ new Date().getFullYear() }}</b>
            <a
              href="/privacy-policy"
              class="privacy-policy-link"
            >
              {{
                $t("Privacy Policy")
              }}
            </a>
          </div>
        </BCol>
        <BCol
          cols="12"
          sm="4"
          lg="2"
        >
          <FooterLinks
            :title="$t('our services')"
            :links="servicesLinks"
            class="mx-4 mx-sm-0"
          />
        </BCol>
        <BCol
          cols="12"
          sm="4"
          lg="2"
          class="footer-links"
        >
          <FooterLinks
            :title="$t('teams')"
            :links="aboutLinks"
            class="mx-4 mx-sm-0"
          />
        </BCol>
        <BCol
          cols="12"
          sm="4"
          lg="2"
        >
          <FooterContact class="mx-4 mx-sm-0" />
        </BCol>
        <BCol
          cols="12"
          class="d-block d-lg-none"
        >
          <div class="copyrights mt-5 mx-4 mx-sm-0">
            <b>{{ $t("copyrights") }} {{ new Date().getFullYear() }}</b>
            <a
              href="#"
              class="privacy-policy-link"
            >
              {{
                $t("Privacy Policy")
              }}
            </a>
          </div>
        </BCol>
      </BRow>
    </BContainer>
  </footer>
</template>

<script>
import FollowButtons from '../FollowButtons/FollowButtons';
import FooterLinks from './FooterLinks';
import FooterContact from './FooterContact';
import servicesLinks from './services.json';
import aboutLinks from './about.json';
import { currentLang } from '@/lang/utils';

export default {
  name: 'Footer',
  components: { FooterContact, FooterLinks, FollowButtons },
  data: () => ({
    servicesLinks: servicesLinks[currentLang],
    aboutLinks: aboutLinks[currentLang],
  }),
};
</script>
