<template>
  <div class="follow-buttons">
    <FacebookBtn />
    <InstagramBtn />
    <TelegramBtn />
    <SnapchatBtn />
    <SoundcloudBtn />
    <YoutubeBtn />
    <AskfmBtn />
    <WhatsappBtn />
    <img
      v-if="QI_RAMADAN_LOGO_GAME"
      :src="BarknaLogo"
      style="height: 24px; margin: 0 8px;"
    >
  </div>
</template>

<script>
import FacebookBtn from './FacebookBtn';
import InstagramBtn from './InstagramBtn';
import TelegramBtn from './TelegramBtn';
import SoundcloudBtn from './SoundcloudBtn';
import SnapchatBtn from './SnapchatBtn';
import YoutubeBtn from './YoutubeBtn';
import AskfmBtn from './AskfmBtn';
import WhatsappBtn from './WhatsappBtn';
import BarknaLogo from '../../images/barkna_logo.png';

export default {
  name: 'FollowButtons',
  components: {
    WhatsappBtn,
    AskfmBtn,
    YoutubeBtn,
    SnapchatBtn,
    SoundcloudBtn,
    TelegramBtn,
    InstagramBtn,
    FacebookBtn,
  },
  data: () => ({
    BarknaLogo,
    QI_RAMADAN_LOGO_GAME: window.QI_RAMADAN_LOGO_GAME
  })
};
</script>

<style lang="scss">
.follow-buttons {
  display: flex;
  margin-top: 10px;

  a {
    margin: 0 8px;
  }
}
</style>
