import menuConfigAr from './menu-config-ar';
import menuConfigEn from './menu-config-en';
import menuConfigTr from './menu-config-tr';

const menuConfig = {
  ar: menuConfigAr,
  en: menuConfigEn,
  tr: menuConfigTr
};

export default menuConfig;
