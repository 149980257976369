import Vue from 'vue';
import VueI18n from 'vue-i18n';
import arabic from './ar-JO.js';
import english from './en-US.js';
import turkish from './tr-TR';
import { currentLang } from './utils';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: currentLang,
  dateTimeFormats: {
    ar: {
      short: { month: 'long', day: 'numeric' },
    },
    en: {
      short: { month: 'long', day: 'numeric' },
    },
    tr: {
      short: { month: 'long', day: 'numeric' },
    },
  },
  messages: {
    ar: arabic,
    en: english,
    tr: turkish,
  },
});

export default i18n;
