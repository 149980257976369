import { render, staticRenderFns } from "./FacebookBtn.vue?vue&type=template&id=cf15c88a&scoped=true&"
import script from "./FacebookBtn.vue?vue&type=script&lang=js&"
export * from "./FacebookBtn.vue?vue&type=script&lang=js&"
import style0 from "./FacebookBtn.vue?vue&type=style&index=0&id=cf15c88a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf15c88a",
  null
  
)

export default component.exports