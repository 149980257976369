export default {
  qudsinfo: "Qudsinfo",
  slogan: "Biggest Quds Site on the Internet",
  "otd-title": "On this day in Jerusalem",
  "learn more": "Learn More",
  "latest news": "Latest News",
  "all news": "All News",
  share: "Share",
  "your aqsa distance": "Your Distance from Aqsa",
  "click aqsa distance": "Find your distance from Aqsa!",
  "our services": "Our Services",
  teams: "Teams",
  "know more": "More",
  language: "Language",
  arabic: "العربية",
  ar: "العربية",
  "arabic-short": "AR",
  english: "English",
  en: "English",
  "english-short": "EN",
  turkish: "Turkish",
  tr: "Turkish",
  "turkish-short": "TR",
  subjects: "Subjects",
  "all information": "All Information",
  back: "Back",
  "show infos": "Show More Information",
  "please select subject": "Please select subject",
  "continue reading": "Continue Reading",
  "no events": "No events today!",
  "info of today": "Today's Info",
  hijri: "Hijri",
  "search placeholder": "Search in Qudsinfo.com ...",
  search: "Search",
  "knowquds description":
    "Learn about the landmarks of Al Aqsa Mosque and the old city directly by seeing the locations of these landmarks on the map with a detailed explanation of each of these landmarks.",
  "alaqsa mosque milestones": "Al-Aqsa Mosque Landmarks",
  "old city milestones": "Old city Landmarks",
  "wallpapers description":
    "A collection of wallpapers for Jerusalem and Al Aqsa to be used in Mobiles and PCs",
  "news description": "Latest news about Jerusalem and Al Aqsa",
  "library description":
    "Find, read and download any book related to Aqsa and Jerusalem.",
  "landmarks description":
    "Search in more than 1400 photo for Al Aqsa and Jerusalem captured by professional photographers supervised by our team",
  "about description":
    "A team of young people who cares about sharing and spreading information about Jerusalem and Al Aqsa mosque.",
  landmarks: "Photos & Landmarks",
  quizzes: "Quizzes",
  "aqsa landmarks": "Al-Aqsa Landmarks",
  "old city landmarks": "Old City Landmarks",
  "beauty landmarks": "Beauty of Jerusalem",
  wallpapers: "Wallpapers",
  "on this day": "On this day",
  "media team": "Media Team",
  "lectures team": "Lectures Team",
  "content team": "Content Team",
  "news team": "News Team",
  "site team": "Website Team",
  "join us": "Join Us",
  "follow us": "Follow us",
  "our info": "Information",
  written: "Written",
  audio: "Audio",
  video: "Video",
  "written info": "Written Info",
  "audio info": "Audio Info",
  "video info": "Video Info",
  news: "News",
  "all quds news": "All Jerusalem News",
  library: "Library",
  "know quds": "Closer to Jerusalem",
  about: "About Us",
  "infos description short": "Written, audio and video Jerusalem information",
  "aqsa distance description short": "Find your distance from Al-Aqsa Mosque",
  "aqsa distance description help":
    "This service requires the use of the GPS location service on your device, be sure to enable it before pressing this button.",
  "on this day description short":
    "Find what events happened in Jerusalem on this any day",
  "knowquds description short":
    "Get a closer view to Jerusalem and Aqsa landmarks",
  "news description short": "Be the first to know latest Jerusalem news",
  "library description short":
    "Find, read and download any book related to Aqsa and Jerusalem",
  "landmarks description short":
    "Photos for any landmark in Jerusalem and Aqsa",
  "quizzes description short": "Test your knowledge about Al-Aqsa Mosque",
  "wallpapers description short":
    "Search for a new wallpaper for your computer or phone",
  copyrights: "© All rights reserved - Qudsinfo",
  total_followers: "Total followers",
  total_reach: "Post reach",
  lectures_count: "No. of lectures",
  students_count: "No. of Students",
  infos_count: "No. of Infos",
  stories_count: "No. of Stories",
  published_count: "Published News",
  reports_count: "Published reports",
  loading: "Loading ...",
  "please select": "Please select",
  "ready to knowquds": "Are you ready to go in a tour in Jerusalem?",
  "knowquds enter aqsa": "Enter Alaqsa Mosque",
  "knowquds enter old-town": "Enter The ancient town",
  "show description": "Show description",
  "hide description": "Hide description",
  "my aqsa distance": "My distance from Al-Aqsa is",
  "find yours on": "Find your distance on",
  your_distance_from_aqsa: "distance_from_aqsa",
  qudsinfo_tag: "qudsinfo",
  phone: "Phone",
  whatsapp: "WhatsApp",
  email: "E-Mail",
};
