export default {
  qudsinfo: "معلومة مقدسية",
  slogan: "أكبر موقع مقدسي على الانترنت",
  "otd-title": "حدث هذا اليوم في القدس",
  "learn more": "المزيد",
  "latest news": "آخر الأخبار",
  "all news": "كل الأخبار",
  share: "مشاركة",
  "Share:": "مشاركة:",
  "your aqsa distance": "بعدك عن الأقصى",
  "click aqsa distance": "احسب بعدك عن الأقصى!",
  "our services": "ماذا نقدم؟",
  teams: "أفرقتنا",
  "know more": "اعرف أكثر",
  language: "اللغة",
  arabic: "العربية",
  ar: "العربية",
  "arabic-short": "AR",
  english: "English",
  en: "English",
  "english-short": "EN",
  turkish: "Turkish",
  tr: "Turkish",
  "turkish-short": "TR",
  "our info": "معلوماتنا",
  written: "المكتوبة",
  audio: "المسموعة",
  video: "فيديو",
  "written info": "المعلومات المكتوبة",
  "audio info": "المعلومات المسموعة",
  "video info": "المعلومات المرئية",
  copyrights: "© جميع الحقوق محفوظة - معلومة مقدسية",
  "privacy policy": "سياسة الخصوصية وشروط الاستخدام",
  services: "خدماتنا",
  about: "من نحن",
  news: "الأخبار",
  "all quds news": "جميع الأخبار المقدسية",
  library: "المكتبة المقدسية",
  "know quds": "القدس عن قرب",
  quizzes: "المسابقات",
  landmarks: "المعالم والصور",
  "aqsa landmarks": "معالم المسجد الأقصى",
  "old city landmarks": "معالم البلدة القديمة",
  "beauty landmarks": "متع ناظريك",
  wallpapers: "Wallpapers",
  "on this day": "حدث في هذا اليوم",
  "who are we": "من نحن",
  "our products": "منتجاتنا",
  "media team": "الفريق الإعلامي",
  "lectures team": "فريق المحاضرات",
  "content team": "فريق المحتوى",
  "news team": "فريق الأخبار",
  "site team": "فريق عمل الموقع",
  "join us": "انضم إلينا",
  contact: "تواصل معنا",
  "follow us": "تابعنا",
  phone: "هاتف",
  whatsapp: "WhatsApp",
  email: "البريد الإلكتروني",
  subjects: "المواضيع",
  "all information": "جميع المعلومات  ",
  back: "عودة",
  "show infos": "عرض المزيد المعلومات",
  "view info": "عرض المعلومة",
  "please select subject": "الرجاء اختيار الموضوع",
  "continue reading": "أكمل القراءة",
  "no events": "لا أحداث في هذا اليوم!",
  "info of today": "معلومة اليوم",
  hijri: "هجري",
  "knowquds description":
    "تعرف على معالم المسجد الأقصى والبلدة القديمة مباشرة عن طريق رؤية مواقع هذه المعالم على الخريطة مع شرح مفصل لكل من هذه المعالم.",
  "alaqsa mosque milestones": "معالم المسجد الأقصى",
  "old city milestones": "معالم البلدة القديمة",
  "wallpapers description":
    "صور مقدسية مناسبة لخلفيات أجهزة الكمبيوتر والهاتف المحمول",
  "news description": "آخر أخبار المسجد الأقصى ومدينة القدس",
  "library description":
    "هنا تجد أي كتاب مختص في القدس والمسجد الأقصى، حيث تستطيع قراءته وتنزيله مجاناً",
  "landmarks description":
    "ابحث في أكثر من 1400 صورة للمسجد الأقصى والبلدة القديمة ومعالمهما تم تصويرها من قبل مصورين محترفين بإشراف فريق معلومة مقدسية",
  "quizzes description": "اختبر معلوماتك المقدسية وتحدى أصدقاءك",
  "about description":
    "فريق شبابي يسعى لنشر المعلومات المتعلقة بالمسجد الأقصى والقدس لنشر الثقافة المقدسية في المجتمع.",
  "search placeholder": "ابحث في معلومة مقدسية ...",
  search: "بحث",
  cancel: "إلغاء",
  km: "كم",
  "calculating distance, please wait": "يرجى الإنتظار، جاري حساب المسافة...",
  "something went wrong please try again": "حدث خطأ ما، يرجى المحاولة لاحقاً",
  "getting your location": "جاري معرفة موقعك...",
  "please try again and allow location permission to find your distance from aqsa":
    "لنستطيع حساب بعدك عن الأقصى يجب أن تقوم بالسماح لنا بالوصول إلى موقعك",
  "please make sure you have enabled location on your device":
    "يرجى التأكد من أن خدمة الموقع مفعلة على جهازك...",
  "your browser is not supported": "عذراً، متصفحك غير مدعوم...",
  total_followers: "عدد المتابعين",
  total_reach: "وصول المنشورات",
  lectures_count: "عدد المحاضرات",
  students_count: "عدد الطلاب",
  infos_count: "عدد المعلومات",
  stories_count: "عدد القصص",
  published_count: "عدد الأخبار",
  reports_count: "عدد التقارير",
  "infos description short": "معلومات مقدسية مكتوبة ومسموعة ومرئية",
  "aqsa distance description short": "اعرف بعدك عن الأقصى من أي نقطة في العالم",
  "aqsa distance description help":
    "تتطلب هذه الخدمة استخدام خدمة موقع GPS على جهازك ، تأكد من تمكينها قبل الضغط على هذا الزر.",
  "on this day description short":
    "تعرف على الأحداث التي حصلت في المسجد الأقصى والقدس",
  "knowquds description short": "تعرف عن قرب على معالم الأقصى والبلدة القديمة",
  "news description short": "كن أول من يعرف أخبار القدس والمسجد الأقصى",
  "library description short": "أكبر مكتبة الكترونية مختصة في المسجد الأقصى",
  "landmarks description short": "صور لجميع معالم الأقصى والبلدة القديمة",
  "quizzes description short": "اختبر معلوماتك المقدسية وتحدى أصدقاءك",
  "wallpapers description short":
    "ابحث عن صورة الأقصى المناسبة لهاتفك أو كمبيوترك",
  "Contact Us": "تواصل معنا",
  "Privacy Policy": "سياسة الخصوصية",
  "Load more": "عرض المزيد",
  "Landmark Location": "موقع المعلم بالنسبة للمسجد الأقصى",
  "Landmark Location relative to Dome of the rock":
    "موقع المعلم بالنسبة لقبة الصخرة",
  "Landmark History": "تاريخ المعلم",
  "Reason of the name": "سبب التسمية",
  "Builder Name": "اسم الباني",
  "ready to knowquds": "هل أنت مستعد لتذهب في جولة داخل القدس؟",
  "knowquds enter aqsa": "دخول للمسجد الأقصى",
  "knowquds enter old-town": "دخول للبلدة القديمة",
  loading: "جار التحميل ...",
  "please select": "الرجاء الاختيار",
  close: "إغلاق",
  "show description": "عرض الوصف",
  "hide description": "إخفاء الوصف",
  "my aqsa distance": "بعدي عن الأقصى هو",
  "find yours on": "اعرف بعدك على",
  your_distance_from_aqsa: "بعدك_عن_الأقصى",
  qudsinfo_tag: "معلومة_مقدسية",
  "Previous landmark": "المعلم السابق",
  "Next landmark": "المعلم التالي",
  "No images uploaded.": "لا يوجد صور متوفرة.",
};
