import Vue from 'vue';
import Vuex from 'vuex';
import fetchInfoOfToday from '../api/fetchInfoOfToday';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    searchActive: false,
    todayInfo: null,
  },
  mutations: {
    setSearch (state, val) {
      state.searchActive = val;
    },
    setTodayInfo (state, val) {
      state.todayInfo = val;
    },
  },
  getters: {
    isSearchActive: (state) => state.searchActive,
    getTodayInfo: (state) => state.todayInfo,
  },
  actions: {
    toggleSearch ({ commit, state }, val) {
      if (typeof val !== 'boolean') {
        val = !state.searchActive;
      }
      commit('setSearch', val);
    },
    loadTodayInfo ({ commit, state }) {
      if (state.todayInfo !== null) {return;}
      fetchInfoOfToday().then((info) => {
        commit('setTodayInfo', info);
      }).catch(err => {
        commit('setTodayInfo', false);
      });
      commit('setTodayInfo', null);
    },
  },
});

export default store;
