export default {
  qudsinfo: "Qudsinfo",
  slogan: "Kudüs'ün En Büyük İnternet Sitesi",
  "otd-title": "Kudüs Tarihte Bugün",
  "learn more": "Learn More",
  "latest news": "Latest News",
  "all news": "All News",
  share: "Paylaşımı",
  "your aqsa distance": "Mescid-i Aksa'dan uzak",
  "click aqsa distance": "Aksa'ya olan mesafenizi bulun!",
  "our services": "Ne Sunuyoruz?",
  teams: "Ekiplerimiz",
  "know more": "Başka",
  language: "Language",
  arabic: "العربية",
  ar: "العربية",
  "arabic-short": "AR",
  english: "English",
  en: "English",
  "english-short": "EN",
  turkish: "Turkish",
  tr: "Turkish",
  "turkish-short": "TR",
  subjects: "Subjects",
  "all information": "All Information",
  back: "Geri",
  "show infos": "Bilgilerin tamamını görüntüle",
  "view info": "Bilgiyi görüntüle",
  "please select subject": "Please select subject",
  "continue reading": "Continue Reading",
  "no events": "No events today!",
  "info of today": "Günün bilgisi",
  hijri: "Hijri",
  "search placeholder": "Search in Qudsinfo.com ...",
  search: "Ara",
  "knowquds description":
    "Eski Şehir ve Mescid-i Aksa'nın eserlerini daha yakından tanıyınız. Harita üzerinde konumlarını ve ayrıntılı açıklamalarını görünüz.",
  "alaqsa mosque milestones": "Mescid-i Aksa Eserleri",
  "old city milestones": "Eski Şehrin Eserleri",
  "wallpapers description":
    "Bilgisayarınıza ya da telefonunuza uygun fotoğraflar",
  "news description": "Latest news about Jerusalem and Al Aqsa",
  "library description":
    "Find, read and download any book related to Aqsa and Jerusalem.",
  "landmarks description":
    "Kudüs Bilgisi Sitesi'nin ekibi gözetiminde ve profesyonel fotoğrafçılar tarafından alınan Eski Şehir ve Mescid-i Aksa'nın 1400'den fazla fotoğrafına göz atınız.",
  "about description":
    "Kudüs ve Mescid-i Aksa ile ilgili bilgileri yayınlayarak toplumda Kudüs kültürünü yaymaya çalışan genç bir ekiptir.",
  landmarks: "Önemli Yapılar ve Fotoğraflar",
  quizzes: "Yarışmalar",
  "aqsa landmarks": "Mescid-i Aksa Eserleri",
  "old city landmarks": "Eski Şehrin Eserleri",
  "beauty landmarks": "Gözlerinin Tadını Çıkar",
  wallpapers: "Ekran Duvar Kağıtları",
  "on this day": "Kudüs Tarihte Bugün",
  "media team": "Medya ekibi",
  "lectures team": "Ders ekibi",
  "content team": "İçerik Ekibi",
  "news team": "Haber ekibi",
  "site team": "web sitesi ekibi",
  "join us": "bize katılın",
  "follow us": "Bizi takip edin",
  "our info": "Bilgi",
  written: "Yazılı",
  audio: "Audio",
  video: "Video",
  "written info": "Yazılı Bilgi",
  "audio info": "Audio Info",
  "video info": "Video Info",
  news: "Haberler",
  "all quds news": "All Jerusalem News",
  library: "Kütüphane",
  "know quds": "Kudüs'e Dair",
  about: "Biz kimiz",
  "infos description short":
    "Kudüs ile ilgili yazılı, işitsel ve görsel bilgiler",
  "aqsa distance description short":
    "Dünyanın herhangi bir noktasından Mescid-i Aksa'ya olan mesafenizi öğrenin",
  "aqsa distance description help":
    "Bu hizmet, cihazınızda GPS konum hizmetinin kullanılmasını gerektirir, bu düğmeye basmadan önce etkinleştirdiğinizden emin olun.",
  "on this day description short":
    "Kudü ve Mescid-i Aksa'da gerçekleştirilmiş olayları öğrenin",
  "knowquds description short":
    "Eski Şehir ve Mescid-i Aksa'nın eserlerini yakından tanıyın",
  "news description short": "Be the first to know latest Jerusalem news",
  "library description short":
    "Kudü ve Mescid-i Aksa ile ilgili en büyük elektronik kütüphane",
  "landmarks description short":
    "Eski Şehir ve Mescid-i Aksa'nın eserleri ile ilgili fotoğraflar",
  "quizzes description short": "Test your knowledge about Al-Aqsa Mosque",
  "wallpapers description short":
    "Bilgisayarınıza ya da telefonunuza uygun bir fotoğraf bulun",
  copyrights: "© Tüm Hakları Saklıdır - Qudsinfo",
  total_followers: "Takipçi Sayısı",
  total_reach: "Organik Erişim",
  lectures_count: "Ders Sayısı",
  students_count: "Öğrenci Sayısı",
  infos_count: "Bilgi Sayısı",
  stories_count: "Hikaye Sayısı",
  published_count: "Yayınlanan Haber Sayısı",
  reports_count: "Hazırlanan Rapor Sayısı",
  loading: "Yükle ...",
  "please select": "Birini seçin",
  "ready to knowquds": "Kudüs içinde tur atmaya hazır mısınız?",
  "knowquds enter aqsa": "Mescid-i Aksa'ya Girin",
  "knowquds enter old-town": "Eski Şehre Girin",
  "show description": "Açıklamayı göster",
  "hide description": "Açıklamayı gizle",
  "my aqsa distance": "My distance from Al-Aqsa is",
  "find yours on": "Find your distance on",
  your_distance_from_aqsa: "distance_from_aqsa",
  qudsinfo_tag: "qudsinfo",
  "calculating distance, please wait":
    "mesafe hesaplanıyor, lütfen bekleyin...",
  "something went wrong please try again":
    "Bir şeyler ters gitti lütfen tekrar deneyin",
  "getting your location": "Konumunuzu almaya çalışıyorum...",
  "please try again and allow location permission to find your distance from aqsa":
    "Aksa'ya olan mesafenizi hesaplamak için konumu aktifleştirin ve erişme izin verin",
  "please make sure you have enabled location on your device":
    "lütfen cihazınızda konum hizmetlerini etkinleştirdiğinizden emin olun.",
  "your browser is not supported": "Tarayıcınız desteklenmiyor.",
  phone: "Telefon",
  whatsapp: "WhatsApp",
  email: "E-Mail",
  "Landmark Location": "Eserin Mescid-i Aksa'ya göre konumu",
  "Landmark Location relative to Dome of the rock":
    "Kubbet-üs Sahra'ya göre öğretmenin konumu\n",
  "Landmark History": "İnşa Tarhi",
  "Reason of the name": "İsimlendirme Nedeni",
  "Builder Name": "Eserin Banisi",
  "Load more": "Daha fazla göster",
  "No images uploaded.": "Gösterilecek fotoğraf yok.",
  "Privacy Policy": "Gizlilik İlkesi",
  "Previous landmark": "Önceki Eser",
  "Next landmark": "Sonraki Eser",
  "Contact Us": "Bize ulaşmak için",
};
